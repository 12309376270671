import React from "react"
import { Link } from "gatsby"
import { css } from "styled-components"

import ContentLayout from "../components/ContentLayout"
import TimeTable from "../components/TimeTable"

import { useUser } from "../hooks/useUser"

import Icon from "../svg/menu/orarend.svg"
import Spinner from "../components/Spinner"

const LogInWarning = () => {
  const { token } = useUser()
  return (
    <div
      css={css`
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
      `}
    >
      {token ? (
        <div
          css={css`
            transform: translate(-50%, -50%);
            position: fixed;
            left: 50%;
            top: 50%;
            z-index: 100;
          `}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          Az órarend használatához kérlek{" "}
          <Link to="/settings">jelentkezz be</Link>.
        </div>
      )}
    </div>
  )
}

const OrarendPage = () => {
  const { loggedIn } = useUser()

  return (
    <ContentLayout icon={Icon} title="Órarend">
      {loggedIn ? <TimeTable /> : <LogInWarning />}
    </ContentLayout>
  )
}

export default OrarendPage
